<template>
  <div>
    <transition name="fade-fast">
      <div class="toast" v-show="toast_show">
        <div class="center">
          <p>{{toast_info.msg}}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'toast',
    props: {
      item: {
        default: ''
      },
    },
    data () {
      return {

      }
    },
    created() {

    },
    mounted() {

    },
    computed: {
      ...mapState({
        toast_show:  state => state.common.toast_show,
        toast_info:  state => state.common.toast_info,
      }),
    },
    watch: {
      toast_show(val) {
        if(val){
          setTimeout(() => {
            this.$store.commit('changeToast', {show: false})
          },2000)
        }
      }
    },
    methods: {

    }
  }
</script>

<style scoped lang="less">
  .center{
    background: rgba(0,0,0,0.8);
    position: absolute;
    width: 670px;
    padding: 30px 0;
    z-index: 100;
    border-radius: 12px;
    text-align: center;
    left: 40px;
    top: 50%;
    transform: translate(0, -50%);
    img{
      width: 60px;
      margin: 0 auto 20px auto;
    }
    p{
      line-height: 33px;
      font-size: 24px;
      color: #fff;
    }
  }
</style>
