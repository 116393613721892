
const state = {
  user_info: {},
  authKey: '',
  sessionId: '',
  openid: '',
  auth_list:{},
};

const getters = {

};

const mutations = {
  saveUserInfo(state, res) {
    state.user_info = res
  },
  saveUserToken(state, res) {
    state.authKey = res
  },
  saveUserSessionId(state, res) {
    state.sessionId = res
  },
  saveAuthList(state, res) {
    state.auth_list = res
  },
  saveOpenId(state, res) {
    state.openid = res
  },
};

const actions = {

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
