import load from "./load";

export default [
  {
    path: '/product',
    name: 'product',
    component: load('product/index'),
    meta: {}
  },
  {
    path: '/product/detail',
    name: '',
    component: load('product/detail'),
    meta: {}
  },
]
