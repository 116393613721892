<template>
  <div id="app">
    <router-view/>
    <Toast />
  </div>
</template>

<script>
  import Toast from './components/toast'
  import Loading from './components/loading'

  export default {
    name: 'App',
    components: {
      Toast,
      Loading,
    },
    data () {
      return {

      }
    },
    created() {
    },
    methods: {

    }
  }
</script>

<style lang="less">
  body{
    overflow: hidden;
    background-color: #fff;
    font-size: 14px;
    font-family: -apple-system-font,PingFangSC-Medium,Helvetica Neue,Helvetica,sans-serif;
    color:  #1C2E3A;
  }

  *{
    box-sizing: border-box;
  }

  ul, li{
    padding: 0;
    margin: 0;
  }

  img{
    width: 100%;
  }

  a, a:hover, a:visited, a:focus, a:active{
    display: inline-block;
    text-decoration: none !important;
    color:#006BE4;
  }

  input, textarea, select{
    border: none;
    outline: none;
    -webkit-appearance:none;
    border-radius: 0;
    &::-webkit-input-placeholder{
      color: @gray_3;
    }
  }

  button::after{
    border: 0;
    outline: 0;
  }

  /**----------------共有部分---------------**/
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  font-size:14px;
  width: 100%;
} 

.page {
  height: 100%;
  box-sizing: border-box;
  margin: 0 16px 0; 
  width:100%;
}

.weui-cell__hd,.van-cell__title,.van-cell__value{
    font-size: 14px;
    color:  #1C2E3A;
}

.van-button {
  margin: 0 auto!important
}

.iIX-css{
  padding-bottom: 100px!important;
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center;
}


::-webkit-scrollbar{
  width:0;
  height:0;
  color:transparent;
}

</style>
