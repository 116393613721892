import Vue from 'vue'
import Vuex from 'vuex'
// import 'es6-promise/auto'

import common from './modules/common'
import user from './modules/user'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  modules: {
    common,
    user,
  },
  strict: debug
})

// store.subscribe((mutation, state) => {
//   console.log(mutation)
//   console.log(state)
// })

export default store
