import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from './config'
import Vant from 'vant';
import "vant/lib/index.css";
import {Local} from "@/lang//vantLocale.js";
import SlideVerify from 'vue-monoplasty-slide-verify'
import { install } from 'vs-tree'
import 'vs-tree/dist/vs-tree.css'


Vue.prototype.$Local = Local;

Vue.config.productionTip = false
Vue.prototype.$config = config

//Toast.setDefaultOptions({ forbidClick: true });
Vue.use(Vant)
Vue.use(SlideVerify)
Vue.use(install)

router.beforeEach((to, from, next) => {
  if (!window.initUrl) {
  window.initUrl = location.href.split("#")[0]
  }
  next()
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
