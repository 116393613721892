import Vue from 'vue'
import VueRouter from 'vue-router'
import load from './load'
import User from './user'
import Store from 'store'
import store from './../store'
import md5 from './../utils/md5'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: load('index') //加载views
  },
  {
    path: '/clear_all',
    name: 'ClearAll',
    component: load('clear_all')
  },
  {
    path: '/error',
    name: 'Error',
    component: load('error')
  },
  ...User,
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  if(to.path === '/clear_all') {
    Store.clearAll()
  }
  //return next()
  document.title = to.meta.title || ''
  console.log('当前的路由是',to.path);
  if(to.path === '/error') {
    next()
    return
  }

  let store_info = {}, query = to.query;
  Store.each(function(value, key) {
    store_info[key] = value
  })

  setInt(store_info)
  setInt(query)

  //---当从微信授权会返回微信语言可初始化----
  if(query.openid){
    store.commit('user/saveOpenId', query.openid)
    Store.set('openid', query.openid)
  }
  if(query.authKey){
    store.commit('user/saveUserToken', query.authKey)
    Store.set('authKey', query.authKey)
  }
  if(query.sessionId){
    store.commit('user/saveUserSessionId', query.sessionId)
    Store.set('sessionId', query.sessionId)
  }
  //------end----------------------//
  let openid= Store.get('openid') || false
  //发现openid均为空则要求授权
  if(to.path !== '/clear_all'){
    if(!openid) {
      var url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe4961fb5a0092787&redirect_uri=${encodeURIComponent('https://www.sayones.cn/index.php?web/alarm/wxredirect')}&response_type=code&scope=snsapi_base&state=${md5('https://www.sayones.cn/index.php?web/alarm/wxredirect')}#wechat_redirect`;
      window.location.href = url;
      return;
    }
  }
  
  //判断当前的设备情况/
  isIPhoneX();
  next();
  return
})


const isIPhoneX = () => {
    var u = navigator.userAgent;
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  console.log("用户的设备isIPhoneX是",isIOS);
    if (isIOS) {        
        if (screen.height == 812 && screen.width == 375){
          Store.set('isIPhoneX',true);
        }
    }else{
      Store.set('isIPhoneX',false);
  }
}

const setInt = (obj) => {
  Object.keys(obj).forEach(key => {
    if(key === 'channel_id' || key === 'customer_id' && obj[key]) {
      obj[key] = parseInt(obj[key])
    }
  })
}

export default router
