
// vant翻译
import {Locale} from 'vant';
import en from './en';
import zh from './zh';
var lang = localStorage.getItem("lang") || "zh-CN";
changeLang(lang);

function changeLang(newlang) {
  switch (newlang) {
      case "zh-CN":
          Locale.use('zh-CN', zh);
          break;
      case "en":
          Locale.use('en', en);
          break;
  }
}

export function Local(mylang) {
  changeLang(mylang)
}