<template>
  <div class="mask" v-show="show">
    <div class="loading">
      <div class="spin">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'loading',
    props: {
      show: Boolean,
    },
    data () {
      return {}
    },
    created() {},
    mounted() {},
    computed: {},
    methods: {}
  }
</script>

<style scoped lang="less">
  .mask{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
  }
  .loading{
    position: absolute;
    width: 100px;
    height: 100px;
    background: rgba(0,0,0,0.8);
    border-radius: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
  }
  .spin{
    width: 100px;
    height: 100px;
    position: relative;
  }
  .spin span{
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    -webkit-animation: load 1.04s ease infinite;
  }
  @-webkit-keyframes load{
    0%{
      opacity: 1;
    }
    100%{
      opacity: 0.2;
    }
  }
  .spin span:nth-child(1){
    left: 0;
    top: 50%;
    margin-top:-8px;
    -webkit-animation-delay:0.13s;
    opacity: 0.9;
  }
  .spin span:nth-child(2){
    left: 14px;
    top: 14px;
    -webkit-animation-delay:0.26s;
    opacity: 0.8;
  }
  .spin span:nth-child(3){
    left: 50%;
    top: 0;
    margin-left: -8px;
    -webkit-animation-delay:0.39s;
    opacity: 0.7;
  }
  .spin span:nth-child(4){
    top: 14px;
    right:14px;
    -webkit-animation-delay:0.52s;
    opacity: 0.6;
  }
  .spin span:nth-child(5){
    right: 0;
    top: 50%;
    margin-top:-8px;
    -webkit-animation-delay:0.65s;
    opacity: 0.5;
  }
  .spin span:nth-child(6){
    right: 14px;
    bottom:14px;
    -webkit-animation-delay:0.78s;
    opacity: 0.4;
  }
  .spin span:nth-child(7){
    bottom: 0;
    left: 50%;
    margin-left: -8px;
    -webkit-animation-delay:0.91s;
    opacity: 0.3;
  }
  .spin span:nth-child(8){
    bottom: 14px;
    left: 14px;
    -webkit-animation-delay:1.04s;
    opacity: 0.2;
  }
</style>
