const state = {
  toast_show: false,
  toast_info: {
    msg: '',
    type: 'success',
  },
  loading_show: false,
};

const getters = {

};

const mutations = {
  changeToast(state, res) {
    state.toast_show = res.show;
    if(res.info) {
      state.toast_info = res.info;
    }
  },
  changeLoading(state, res) {
    state.loading_show = res;
  },
};

const actions = {

};

export default {
  state,
  getters,
  actions,
  mutations
}
